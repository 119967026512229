import React from 'react'
import styled from 'styled-components'

import mwdlLogo from '../../images/mwdl-logo.svg'

export default function MWDL() {
  return (
    <section>
      <Wrapper>
        <Link
          href="https://www.midwestdesignlab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo src={mwdlLogo} alt="Designed by MWDL" />
          <span>Design by MWDL</span>
        </Link>
      </Wrapper>
    </section>
  )
}

const Wrapper = styled.div`
  align-items: center;
  background-color: #000;
  display: flex;
  font-family: 'Nunito Sans', 'sans-serif';
  height: 100px;
  justify-content: center;
`

const Link = styled.a`
  color: #fff;
  text-decoration: none;
`

const Logo = styled.img`
  display: block;
  height: auto;
  margin: 0 auto 8px auto;
  max-width: 32px;
  width: 100%;
`
