import React from 'react'
import styled from 'styled-components'

import iconFacebook from '../../images/icon-fb.svg'
import iconTwitter from '../../images/icon-twitter.svg'
import iconInstagram from '../../images/icon-instagram.svg'

export default function Footer() {
  return (
    <StyledFooter>
      <Sitemap>
        <NavList>
          <NavTitle>Site</NavTitle>
          <NavLink href="/">Home</NavLink>
          <NavLink href="/about">About</NavLink>
          <NavLink href="/praise">Praise</NavLink>
        </NavList>
        <NavList>
          <NavTitle>Contact</NavTitle>
          <NavLink href="mailto:contact@trinavargo.com">
            contact@trinavargo.com
          </NavLink>
        </NavList>
        <NavList>
          <NavTitle center>Connect</NavTitle>
          <Icons>
            <NavLink
              href="https://www.facebook.com/USIrelandAlliance"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={iconFacebook} alt="Facebook" />
            </NavLink>
            <NavLink
              href="https://twitter.com/trinavargo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={iconTwitter} alt="Twitter" />
            </NavLink>
            <NavLink
              href="https://www.instagram.com/mitchellscholars"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={iconInstagram} alt="Instagram" />
            </NavLink>
          </Icons>
        </NavList>
      </Sitemap>
      <Logo>Shenanigans</Logo>
      <Paragraph>by Trina Vargo</Paragraph>
      <Paragraph>
        {`© ${new Date().getFullYear()} Cavan Bridge Press. All Rights Reserved.`}
      </Paragraph>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  padding: 50px;
`

const Sitemap = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`

const NavList = styled.div`
  font-family: 'Lusitana';
  font-size: 14px;
  margin-bottom: 1.25rem;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
    &:first-child {
      margin-right: 50px;
    }
    &:last-child {
      margin-left: auto;
    }
  }
`

const NavTitle = styled.h3`
  color: #008b27;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 8px;
  margin-top: 0;
  ${({ center }) => center && `text-align: center`};
`

const NavLink = styled.a`
  color: #303030;
  display: block;
  letter-spacing: 1px;
  line-height: 2.14;
  text-decoration: none;
`

const Icons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  img {
    display: block;
    margin: 0 10px;
  }
`

const Logo = styled.h1`
  color: #007e22;
  font-size: 24px;
  font-weight: normal;
  margin: 0 auto 0.875rem auto;
  text-align: center;
`

const Paragraph = styled.p`
  color: #303030;
  font-size: 0.875rem;
  margin: 0 auto 0.875rem auto;
  max-width: 180px;
  text-align: center;
  @media (min-width: 768px) {
    max-width: 100%;
  }
`
