import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { darken } from 'polished'

import { ORDER_URL } from '../../lib/constants'

const Header = () => (
  <header>
    <Container>
      <Logo>
        <Link to="/">Shenanigans</Link>
      </Logo>
      <Navigation>
        <NavLink to="/about">About the Author</NavLink>
        <NavLink to="/praise">Praise</NavLink>
      </Navigation>
      <OrderNow href={ORDER_URL} target="_blank" rel="noopener noreferrer">
        order now
      </OrderNow>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const Container = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 60px;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 10px 0 20px;
  width: 100%;
  @media screen and (min-width: 768px) {
    height: 80px;
    padding: 0 20px 0 30px;
  }
`

const Logo = styled.h1`
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 0;
  margin-right: 40px;
  a {
    color: #007e22;
    text-decoration: none;
  }
`

const Navigation = styled.nav`
  display: none;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`

const NavLink = styled(Link)`
  color: #303030;
  font-size: 1rem;
  text-decoration: none;
  transition: color 200ms ease;
  &:hover {
    color: #007e22;
  }
  &:not(:last-child) {
    margin-right: 30px;
  }
`

const OrderNow = styled.a`
  align-items: center;
  background-color: #008b27;
  border-radius: 2px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  font-family: 'Nunito Sans', sans-serif;
  height: 40px;
  justify-content: center;
  margin-left: auto;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 200ms ease;
  width: 160px;
  &:hover {
    background-color: ${darken(0.025, `#008b27`)};
  }
`
