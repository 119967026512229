import React from 'react'
import styled from 'styled-components'

import pressLogo from '../../images/press-logo.png'

export default function PressLogo() {
  return (
    <section>
      <Press>
        <Logo src={pressLogo} alt="Cavan Bridge Press" />
      </Press>
    </section>
  )
}

const Press = styled.div`
  align-items: center;
  background-color: #020d25;
  display: flex;
  height: 100px;
  justify-content: center;
`

const Logo = styled.img`
  display: block;
  height: auto;
  margin: 0;
  max-width: 48px;
  width: 100%;
`
