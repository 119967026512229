import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import './layout.css'

import Header from './header'
import PressLogo from '../PressLogo'
import Footer from './footer'
import MWDL from '../MWDL'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Header />
        <Container>
          <main>{children}</main>
          <PressLogo />
          <Footer />
          <MWDL />
        </Container>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Container = styled.div`
  margin: 0 auto;
  max-width: 1440px;
`
